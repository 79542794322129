import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
export const ROUTES_MODULE_FIRST_NAME = "stage";
export const routesConvert = ({ path, name, component, ...remain }, isRoot) => {
  return {
    path: `${isRoot ? `/${ROUTES_MODULE_FIRST_NAME}` : ""}${path}`,
    name: `${isRoot ? ROUTES_MODULE_FIRST_NAME : ""}${
      isRoot ? name.charAt(0).toUpperCase() + name.slice(1) : name
    }`,
    component: () => import(`@/views${component}`),
    ...remain,
  };
};
export const routesData = {
  index: {
    path: "/",
    name: "Index",
    component: "/Index.vue",
  },
};
export const routesModuleData = {
  detail: {
    path: "/detail",
    name: "Detail",
    component: "/Detail.vue",
  },
  game: {
    path: "/game",
    name: "Game",
    component: "/Game.vue",
  },
};
const objectConvertToArray = (obj, isRoot) => {
  const arr = [];
  Object.keys(obj).forEach((key) => arr.push(routesConvert(obj[key], isRoot)));
  return arr;
};
const routes = [
  {
    ...routesConvert(routesData.index),
    children: [...objectConvertToArray(routesModuleData)],
  },
  ...objectConvertToArray(routesModuleData, true),
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_SUB_PATH,
  routes,
});

export default router;
