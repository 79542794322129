export default {
  messages: [], // 私聊聊天信息（[message, message, message...]）
  messagesAll: [], // 公共聊天信息（[message, message, message...]）
  userInfo: {}, // 用户信息
  config: {}, // 服务器配置
  detail: {}, // 活动详情
  textChatUserList: [], // 文字聊天左侧用户列表
  talkAuthApplyList: [], // 发言申请列表
  agoraRtc: {}, // 通信对象
  agoraLive: {}, // 直播对象
  agoraLiveListener: {}, // 直播监听
  agoraLiveMoreList: [], // 直播对象-更多屏幕组
  agoraLiveMoreScreenBindList: [], // 直播对象-屏幕id绑定组
  agoraLiveUserPublishedListenerMoreScreenBindList: {}, // 推流监听-共享屏幕列表
  videoChatUserList: [], // 视频聊天用户列表
  doingVideoProjectList: [], // 正在投屏列表
  prepareList: [], // 画布视频dom列表
  canvasTimers: {}, // 画布定时器集合
  soundControl: 1, // 声音控制
  onlineUserList: [], // 在线用户列表
};
