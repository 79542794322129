import axios from "axios";
import qs from "qs";
import { Toast } from "vant";
// import { default as router, routesData } from "../../router/index";
import store from "../../plugins/vuex/index";
import { clearToken } from "./storage";
const err400 = (code) => {
  if (code !== 400) return "next";
  // Toast.fail("服务异常，请稍后再试：400！");
};
const err403 = (code) => {
  if (code !== 403) return "next";
  // Toast.fail("服务异常，请稍后再试：403！");
};
const err500 = (code) => {
  if (code !== 500) return "next";
  Toast.fail("服务异常，请稍后再试：500！");
};
const err504 = (code) => {
  if (code !== 504) return "next";
  Toast.fail({
    message: "登录过期，请重新登录",
    duration: 3000,
  });
  setTimeout(() => {
    // router
    //   .replace({
    //     name: routesData.index.name,
    //   })
    //   .catch(() => {});
    clearToken();
    store.dispatch("clearUserInfo", "");
    store.dispatch("clearConfig", "");
  }, 1000);
};
const errorHandlerChain = [err400, err403, err500, err504];
const errorHandle = (error) => {
  for (const handler of errorHandlerChain) {
    const result = handler(error);
    if (result !== "next") break;
  }
};

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.timeout = 300000; // 5分钟
axios.interceptors.request.use((config) => {
  if (!config.headers.requestType || config.headers.requestType !== "noToken") {
    if (localStorage.getItem("token")) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
  }
  if (sessionStorage.getItem("activity_id")) {
    config.headers["activity-id"] = sessionStorage.getItem("activity_id") || 0;
  }
  if (config.url.indexOf("/api/mecard") >= 0) {
    config.headers = {};
    if (config.url.indexOf("wechat/getQRCode") >= 0) {
      config.responseType = "arraybuffer";
    }
    config.url =
      process.env.VUE_APP_PROXY_URL_MECARD +
      config.url.replace("/api/mecard", "");
  }
  if (config.url.indexOf("/api/common") >= 0) {
    config.url = config.url.replace("/api/common", "/common");
  }
  return config;
});
axios.interceptors.response.use(
  (res) => {
    errorHandle(res.data.code);
    return res;
  },
  (err) => {
    errorHandle(err.response.data.code);
  }
);

const urlConvert = (url) => {
  return url.indexOf(process.env.VUE_APP_EXTERNAL_LINK) >= 0
    ? url.replace(`${process.env.VUE_APP_EXTERNAL_LINK}`, "")
    : `/api${url}`;
};

export const get = (url, params = {}, token = true) => {
  console.log(url, urlConvert(url));
  if (token)
    return axios.get(urlConvert(url), { params }).then((res) => res.data);
  return axios
    .get(urlConvert(url), { params, headers: { requestType: "noToken" } })
    .then((res) => res.data);
};
export const post = (url, params = {}, token = true) => {
  if (token)
    return axios
      .post(urlConvert(url), qs.stringify(params))
      .then((res) => res.data);
  return axios
    .post(urlConvert(url), qs.stringify(params), {
      headers: { requestType: "noToken" },
    })
    .then((res) => res.data);
};
export const patch = (url, params = {}, token = true) => {
  if (token)
    return axios
      .patch(urlConvert(url), qs.stringify(params))
      .then((res) => res.data);
  return axios
    .patch(urlConvert(url), qs.stringify(params), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then((res) => res.data);
};
export const put = (url, params = {}, token = true) => {
  if (token)
    return axios
      .put(urlConvert(url), qs.stringify(params))
      .then((res) => res.data);
  return axios
    .put(urlConvert(url), qs.stringify(params), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then((res) => res.data);
};
export const deletes = (url, params = {}, token = true) => {
  if (token)
    return axios.delete(urlConvert(url), { params }).then((res) => res.data);
  return axios
    .delete(urlConvert(url), {
      params,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then((res) => res.data);
};

export const uploads = (url, params = {}, token = true) => {
  if (token) {
    return axios.post(urlConvert(url), params, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=",
      },
    });
  }
  return axios.post(urlConvert(url), { params });
};
