/* eslint-disable no-undef */
import { getUrlParam } from "./utils";
// import { checkToken, setToken, updateUserInfo } from "./storage";
import { get } from "./http";
// import { routesData } from "../../router/index";
const AUTH_URL =
  "https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=snsapi_userinfo&state=STATE&forcePopup=true&forceSnapShot=true#wechat_redirect";
export const getAuthUrl = (redirectUrl) => {
  return AUTH_URL.replace("APPID", process.env.VUE_APP_WECHAT_APPID).replace(
    "REDIRECT_URI",
    encodeURIComponent(redirectUrl)
  );
};
/**
 * 获取微信授权
 */
export const doAuth = () => {
  return new Promise((resolve) => {
    const test_id = getUrlParam("test_id");
    if (test_id) {
      sessionStorage.setItem("test_id", test_id);
    } else {
      sessionStorage.setItem("test_id", "");
    }
    const activity_id = getUrlParam("id");
    if (activity_id) {
      sessionStorage.setItem("activity_id", activity_id);
    }
    resolve(true);
    // if (isWechat()) {
    //   checkToken().then((tokenPassed) => {
    //     if (!tokenPassed) {
    //       const code = getUrlParam("code");
    //       const debug = getUrlParam("debug");
    //       console.log(code, "code");
    //       if (!code) {
    //         resolve(false);
    //         location.href = getAuthUrl(location.href);
    //       } else {
    //         if (!debug) {
    //           get("/wechat/auth-login", {
    //             code: code,
    //           }).then((res) => {
    //             if (res.code === 200 && res.data) {
    //               setToken(res.data.token);
    //               updateUserInfo();
    //               resolve(true);
    //             } else {
    //               resolve(false);
    //             }
    //           });
    //         }
    //       }
    //     } else {
    //       updateUserInfo();
    //       resolve(true);
    //     }
    //   });
    // } else {
    //   checkToken().then((tokenPassed) => {
    //     if (tokenPassed) {
    //       updateUserInfo();
    //     }
    //   });
    //   resolve(true);
    // }
  });
};
/**
 * 微信配置初始化
 */
export const initConfig = (url) => {
  return new Promise((resolve) => {
    get("/wechat/share", {
      localUrl: url,
    }).then((res) => {
      if (res.code === 200) {
        setConfig(res.data).then((pass) => {
          if (pass) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(false);
      }
    });
  });
};
/**
 * 微信配置注入
 */
export const setConfig = (config) => {
  return new Promise((resolve) => {
    const { appId, timestamp, nonceStr, signature } = config;
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId, // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature, // 必填，签名
      jsApiList: [
        "updateAppMessageShareData",
        "updateTimelineShareData",
        "wx-open-launch-weapp",
        "wx-open-subscribe",
        "chooseImage",
      ], // 必填，需要使用的JS接口列表,
      openTagList: ["wx-open-launch-weapp", "wx-open-subscribe"],
      fail(err) {
        console.log("setShareConfig err", err);
      },
    });
    resolve(true);
  });
};
/**
 * 微信分享注入
 */
export const wxShare = (option) => {
  !option && (option = {});
  const url = option.url || location.href;
  return initConfig(url).then((pass) => {
    if (pass) {
      const { title, link, desc, imgUrl } = option;
      // console.log(option, "wxShare");
      const shareParam = {
        title: title || process.env.VUE_APP_SHARE_DEFAULT_TITLE, // 分享标题
        desc: desc || process.env.VUE_APP_SHARE_DEFAULT_DESC, // 分享描述
        link: link || process.env.VUE_APP_SUB_URL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl || process.env.VUE_APP_SHARE_IMG, // 分享图标
      };
      wx.ready(() => {
        // 需在用户可能点击分享按钮前就先调用
        wx.updateTimelineShareData({
          ...shareParam,
          success() {
            console.log("updateTimelineShareData success");
          },
          fail(err) {
            console.log(err, "updateTimelineShareData");
          },
        });
        wx.updateAppMessageShareData({
          ...shareParam,
          success() {
            console.log("updateAppMessageShareData success");
          },
          fail(err) {
            console.log(err, "updateAppMessageShareData");
          },
        });
      });
      parent.wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          wx.miniProgram.postMessage({
            data: {
              url: shareParam.link,
              title: shareParam.title,
              img: shareParam.imgUrl,
            },
          });
        }
      });
    }
    return pass;
  });
};
/**
 * 微信拍照
 */
export const wxChooseImage = (sourceType) => {
  return new Promise((resolve) => {
    wx.chooseImage({
      count: 1, // 默认9
      sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
      sourceType: Array.isArray(sourceType) ? [...sourceType] : [sourceType], // 可以指定来源是相册还是相机，默认二者都有
      success: function (res) {
        // var localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
        resolve(res.localIds);
      },
    });
  });
};
/**
 * 分享图配置
 */
export const shareData = {
  index: {
    title: "",
    desc: "",
    img: "",
    link: "",
  },
};
