// 添加私聊聊天记录
export const addMessage = ({ commit }, data) => {
  commit("addMessage", data);
};

// 添加公共聊天记录
export const addMessageAll = ({ commit }, data) => {
  commit("addMessageAll", data);
};

// 保存用户信息
export const saveUserInfo = ({ commit }, data) => {
  commit("saveUserInfo", data);
};

// 保存服务器配置
export const saveConfig = ({ commit }, data) => {
  commit("saveConfig", data);
};

// 保存活动详情
export const saveDetail = ({ commit }, data) => {
  commit("saveDetail", data);
};

// 清空用户信息
export const clearUserInfo = ({ commit }) => {
  commit("clearUserInfo");
};

// 清空服务器配置
export const clearConfig = ({ commit }) => {
  commit("clearConfig");
};

// 清空活动详情
export const clearDetail = ({ commit }) => {
  commit("clearDetail");
};

// 添加文字聊天左侧列表用户
export const addTextChatUserList = ({ commit }, data) => {
  commit("addTextChatUserList", data);
};

// 保存文字聊天左侧列表用户
export const saveTextChatUserList = ({ commit }, data) => {
  commit("saveTextChatUserList", data);
};

// 添加发言申请用户列表
export const addTalkAuthApplyList = ({ commit }, data) => {
  commit("addTalkAuthApplyList", data);
};

// 保存发言申请用户列表
export const saveTalkAuthApplyList = ({ commit }, data) => {
  commit("saveTalkAuthApplyList", data);
};

// 取消发言申请用户列表
export const cancelTalkAuthApplyList = ({ commit }, data) => {
  commit("cancelTalkAuthApplyList", data);
};

// 保存通信对象
export const saveAgoraRtc = ({ commit }, data) => {
  commit("saveAgoraRtc", data);
};

// 保存直播对象
export const saveAgoraLive = ({ commit }, data) => {
  commit("saveAgoraLive", data);
};

// 添加直播对象组-给更多屏幕
export const addAgoraLiveMoreList = ({ commit }, data) => {
  commit("addAgoraLiveMoreList", data);
};

// 保存直播对象组-给更多屏幕
export const saveAgoraLiveMoreList = ({ commit }, data) => {
  commit("saveAgoraLiveMoreList", data);
};

// 添加直播对象-屏幕id绑定组
export const addAgoraLiveMoreScreenBindList = ({ commit }, data) => {
  commit("addAgoraLiveMoreScreenBindList", data);
};

// 保存直播对象-屏幕id绑定组
export const saveAgoraLiveMoreScreenBindList = ({ commit }, data) => {
  commit("saveAgoraLiveMoreScreenBindList", data);
};

// 添加视频聊天用户列表
export const addVideoChatUserList = ({ commit }, data) => {
  commit("addVideoChatUserList", data);
};

// 保存视频聊天用户列表
export const saveVideoChatUserList = ({ commit }, data) => {
  commit("saveVideoChatUserList", data);
};

// 添加正在投屏列表
export const addDoingVideoProjectList = ({ commit }, data) => {
  commit("addDoingVideoProjectList", data);
};

// 保存正在投屏列表
export const saveDoingVideoProjectList = ({ commit }, data) => {
  commit("saveDoingVideoProjectList", data);
};

// 保存推流监听-共享屏幕列表
export const saveAgoraLiveUserPublishedListenerMoreScreenBindList = (
  { commit },
  data
) => {
  commit("saveAgoraLiveUserPublishedListenerMoreScreenBindList", data);
};

// 保存画布视频dom列表
export const savePrepareList = ({ commit }, data) => {
  commit("savePrepareList", data);
};

// 保存直播监听
export const saveAgoraLiveListener = ({ commit }, data) => {
  commit("saveAgoraLiveListener", data);
};

// 保存画布定时器集合
export const saveCanvasTimers = ({ commit }, data) => {
  commit("saveCanvasTimers", data);
};

// 保存声音控制
export const saveSoundControl = ({ commit }, data) => {
  commit("saveSoundControl", data);
};

// 保存在线用户列表
export const saveOnlineUserList = ({ commit }, data) => {
  commit("saveOnlineUserList", data);
};
